import _unionBy from 'lodash/unionBy'

// Define the event type
interface FolderChangeEvent {
  account?: any
  picked?: any
}

interface AccountChangeEvent {
  account?: any
  picked?: any
}

export function Controller(
  accountsData,
  foldersTree,

  recordsMove,
  recordsSelection,
  context,
  $http,
  BASE_URL,
  API_VERSION,
  SubscriptionsData
) {
  'ngInject'

  const $ctrl = this
  // Initialize success flag to false
  $ctrl.success = true

  $ctrl.matchedIds = {
    fieldIds: [],
    stateIds: [],
    frequencyIds: []
  }

  // Initialize mismatchCounts
  $ctrl.mismatchCounts = {
    field: 0,
    state: 0,
    frequency: 0
  }

  // Define the functions to be used in the view.html file
  $ctrl.control = {
    onChangeAccount,
    onFolderChange,
    createTransfer,
    prepareTransfer
  }

  // added to
  $ctrl.$onInit = () => {
    this.saving = false
    this.records = this.resolve.records
    this.sharedFolders = this.records.some((record) => record.folder.partner)
    this.error = null
    this.errors = []
    this.folder = null
    this.account = context.get('account')
    getAccounts()
    SubscriptionsData.fetchSubscription(this.account.subscription.id).then((subscription) => {
      this.subscription = subscription
    })
  }

  this.save = (currentAccountId, selectedAccountId, records?, matchedIds?) => {
    if (this.folder && this.folder.id) {
      this.saving = true

      if (isSameAccount(currentAccountId, selectedAccountId)) {
        // The account is the same, use move functionality
        recordsMove
          .batchMove(this.records, this.folder.id)
          .then(() => {
            this.dismiss({ $value: 'saved' })
            recordsSelection.remove(this.records)
          })
          .catch((rejection) => {
            this.saving = false
            this.error = rejection.$response.data.error
          })
      } else {
        // The account is different, use transfer functionality
        $ctrl.control.createTransfer(currentAccountId, selectedAccountId, records, matchedIds)
      }
    }
  }

  this.folderChange = (event) => {
    this.folder = event.picked
  }

  function isSameAccount(currentAccountId, selectedAccountId) {
    return currentAccountId === selectedAccountId
  }

  /**
   * Function to get accounts
   */
  function getAccounts() {
    accountsData
      .getAll(
        accountsData.init(null, {
          include: null
        }),
        false
      )
      .then((response) => {
        $ctrl.accounts = response
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  /**
   * Function to handle if the account is changed in the dropdown
   */
  function onChangeAccount() {
    $ctrl.folder = null
    accountsData.fetchAccount($ctrl.selectedAccount.id).then((response) => {
      $ctrl.selectedAccount = response
      $ctrl.control.prepareTransfer($ctrl.currentAccount.id, $ctrl.selectedAccount.id, $ctrl.records)
    })
  }

  /**
   * Function to set the destination folder when it's been clicked
   */
  function onFolderChange(event: FolderChangeEvent) {
    $ctrl.folder = event.picked
  }

  /**
   * Function to create (But not run) the transfer
   */
  function createTransfer(sourceId, destinationId, records, mappedIds) {
    const endpointUrl = `${BASE_URL}/transfers/actions/run`

    let folderId = $ctrl.folder.id
    const fields = mappedIds.fieldIds
    const states = mappedIds.stateIds
    const frequencies = mappedIds.frequencyIds
    let recordIds = []

    for (let i = 0; i < records.length; i++) {
      recordIds.push(records[i].id)
    }

    $http({
      method: 'POST',
      url: endpointUrl,
      headers: {
        Accept: API_VERSION
      },
      data: {
        source_id: sourceId,
        destination_id: destinationId,
        destination_folder_id: folderId,
        fields: fields,
        states: states,
        frequencies: frequencies,
        records: recordIds
      }
    })
      .then(saveSuccess)
      .catch((error) => {
        throw new Error(error)
      })
  }

  function saveSuccess() {
    alert('The record has been successfully transferred.')
    $ctrl.dismiss({ $value: 'saved' })

    dispatchEvent(new CustomEvent('record_tran_ok'))
  }

  function prepareTransfer(sourceId, destinationId, records) {
    $ctrl.errors = []
    $ctrl.error = null

    let recordIds = []

    for (let i = 0; i < records.length; i++) {
      recordIds.push(records[i].id)
    }

    if (isSameAccount(sourceId, destinationId)) {
      $ctrl.success = true
      return
    } else {
      $ctrl.success = false
    }

    const endpointUrl = `${BASE_URL}/transfers`

    $http({
      method: 'POST',
      url: endpointUrl,
      headers: {
        Accept: API_VERSION
      },
      data: {
        source_id: sourceId,
        destination_id: destinationId,
        record_ids: recordIds
      }
    })
      .then((response) => {
        try {
          const responseData = response.data
          const mapping = responseData.data.mapping

          const sourceMappings = {
            fields: [...mapping.source.fields],
            frequencies: [...mapping.source.frequencies],
            states: [...mapping.source.states]
          }

          const destinationMappings = {
            fields: [...mapping.destination.fields],
            frequencies: [...mapping.destination.frequencies],
            states: [...mapping.destination.states]
          }

          const comparisonResult = compareMappings(sourceMappings, destinationMappings)

          $ctrl.mismatchCounts = comparisonResult.mismatchCounts

          $ctrl.matchedIds = comparisonResult.mappedIds

          console.error(comparisonResult.errors)

          // Assign the errors to the controller's scope
          $ctrl.errors = comparisonResult.errors

          console.error($ctrl.errors)

          if (comparisonResult.errors.length > 0) {
            // Loop through each error and log it
            for (let error of comparisonResult.errors) {
              throw new Error(error)
            }
          } else {
            $ctrl.success = true
          }
        } catch (error) {
          throw new Error(error)
        }
      })
      .catch((error) => {
        // Handle any errors that occurred while making the HTTP request or comparing mappings
        throw new Error(error)
      })
  }

  /**
   * Function to compare mappings
   */
  function compareMappings(sourceMappings, destinationMappings) {
    // Initialize arrays to store matched information and errors
    let matchedFields = []
    let matchedStates = []
    let matchedFrequencies = []

    let fieldMismatchCount = 0
    let stateMismatchCount = 0
    let frequencyMismatchCount = 0

    let fieldIdMap = {}
    let stateIdMap = {}
    let frequencyIdMap = {}
    let errors = []

    // Loop over fields in sourceMappings and destinationMappings
    for (let sourceField of sourceMappings.fields) {
      let isMatched = false
      for (let destinationField of destinationMappings.fields) {
        if (sourceField.key === destinationField.key || sourceField.label === destinationField.label) {
          isMatched = true
          matchedFields.push(sourceField)
          fieldIdMap[sourceField.id] = destinationField.id
          break
        }
      }

      if (!isMatched) {
        errors.push(`Field ${sourceField.key} does not match.`)
        fieldMismatchCount++
      }
    }

    // Loop over states in sourceMappings and destinationMappings

    for (let sourceState of sourceMappings.states) {
      let isMatched = false
      for (let destinationState of destinationMappings.states) {
        if (sourceState.name === destinationState.name) {
          isMatched = true
          matchedStates.push(sourceState)
          stateIdMap[sourceState.id] = destinationState.id
          break
        }
      }

      if (!isMatched) {
        errors.push(`State ${sourceState.name} does not match.`)
        stateMismatchCount++
      }
    }

    // Loop over frequencies in sourceMappings and destinationMappings
    for (let sourceFrequency of sourceMappings.frequencies) {
      let isMatched = false

      for (let destinationFrequency of destinationMappings.frequencies) {
        if (sourceFrequency.name === destinationFrequency.name) {
          isMatched = true
          matchedFrequencies.push(sourceFrequency)
          frequencyIdMap[sourceFrequency.id] = destinationFrequency.id
          break
        }
      }

      if (!isMatched) {
        errors.push(`Frequency ${sourceFrequency.name} does not match.`)
        frequencyMismatchCount++
      }
    }

    // matching field ids
    return {
      mismatchCounts: {
        // Include separate mismatch counts in the result
        field: fieldMismatchCount,
        state: stateMismatchCount,
        frequency: frequencyMismatchCount
      },
      matchedData: {
        fields: matchedFields,
        states: matchedStates,
        frequencies: matchedFrequencies
      },
      mappedIds: {
        fieldIds: fieldIdMap,
        stateIds: stateIdMap,
        frequencyIds: frequencyIdMap
      },
      errors: errors
    }
  }

  // Initialize controller state

  $ctrl.saving = false
  this.records = this.resolve.records
  this.error = null
  this.errors = []
  this.folder = null

  // Current account
  $ctrl.currentAccount = context.get('account')

  $ctrl.accounts = []
  this.showAccountSelector = false

  $ctrl.selectedAccount = $ctrl.currentAccount
}
